<template>
  <b-tabs no-fade>
    <b-tab active>
      <template #title>
        <feather-icon class="mb-lg-none" icon="TagIcon" />
        <span class="d-none d-lg-block">Codis promocionals</span>
      </template>
      <b-card>
        <promotions-table
          :items="promoCodes"
          :fields="promoCodesFields"
          :loading="loading"
          :new-button-text="$t('Nou codi')"
          show-button
          show-search
          show-filters
          show-actions
          @new-click="onNewPromoCodeButtonClicked"
          @item-click="onPromoCodeClicked"
          @item-edit-click="onEditPromoCodeClicked"
          @item-delete-click="onDeletePromoCodeClicked"
        />
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon class="mb-lg-none" icon="PercentIcon" />
        <span class="d-none d-lg-block">Descomptes</span>
      </template>
      <b-card>
        <promotions-table
          :items="discounts"
          :fields="discountsFields"
          :loading="loading"
          :new-button-text="$t('Nou descompte')"
          show-button
          show-search
          show-filters
          show-actions
          @new-click="onNewDiscountButtonClicked"
          @item-click="onDiscountClicked"
          @item-edit-click="onEditDiscountClicked"
          @item-delete-click="onDeleteDiscountClicked"
        />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import { getPromotionTypeName, notifyError } from "@/utils/methods";
import { BCard, BTabs, BTab } from "bootstrap-vue";
import PromotionsTable from "@/views/promotions/list/components/PromotionsTable.vue";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    PromotionsTable,
  },
  data() {
    return {
      promoCodesFields: [
        { key: "title", label: this.$t("Descripció"), sortable: true },
        { key: "code", label: this.$t("Codi"), sortable: true },
        { key: "type", label: this.$t("Tipus"), sortable: true },
        { key: "value", label: this.$t("Valor"), sortable: true },
        { key: "active", label: this.$t("Habilitat"), sortable: true },
        { key: "expired", label: this.$t("Caducat"), sortable: true },
        { key: "valid", label: this.$t("Vàlid"), sortable: true },
      ],
      discountsFields: [
        { key: "title", label: this.$t("Descripció"), sortable: true },
        { key: "active", label: this.$t("Habilitat"), sortable: true },
        { key: "expired", label: this.$t("Caducat"), sortable: true },
        { key: "valid", label: this.$t("Vàlid"), sortable: true },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["promotions/loading"];
    },
    promotions() {
      return this.$store.getters["promotions/promotions"];
    },
    promoCodes() {
      return this.$store.getters["promotions/promoCodes"].map((discount) => ({
        uuid: discount.uuid,
        title: discount.title,
        code: discount.code,
        type:
          getPromotionTypeName(discount.type, this.$i18n.locale) ||
          this.$t("No definit"),
        value: discount.value,
        active: discount.active,
        expired: discount.expired,
        valid: discount.valid,
      }));
    },
    discounts() {
      return this.$store.getters["promotions/discounts"].map((discount) => ({
        uuid: discount.uuid,
        title: discount.title,
        active: discount.active,
        expired: discount.expired,
        valid: discount.valid,
      }));
    },
  },
  created() {
    this.fetchPromotions();
  },
  methods: {
    fetchPromotions() {
      this.$store
        .dispatch("promotions/fetchPromotions")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPromotions.title"),
            this.$t("errors.fetchPromotions.description")
          )
        );
    },
    onNewPromoCodeButtonClicked() {
      this.$router.push({
        name: "foravila-new-promotion",
        query: { category: "promo-code" },
      });
    },
    onPromoCodeClicked(promotionUuid) {
      this.$router.push({
        name: "foravila-promotion-view",
        params: { promotionUuid },
      });
    },
    onEditPromoCodeClicked() {
      return null;
    },
    onDeletePromoCodeClicked() {
      return null;
    },
    onNewDiscountButtonClicked() {
      this.$router.push({
        name: "foravila-new-promotion",
        query: { category: "discount" },
      });
    },
    onDiscountClicked(promotionUuid) {
      this.$router.push({
        name: "foravila-promotion-view",
        params: { promotionUuid },
      });
    },
    onEditDiscountClicked() {
      return null;
    },
    onDeleteDiscountClicked() {
      return null;
    },
  },
};
</script>
