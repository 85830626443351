<template>
  <div class="static-table">
    <!-- BUTTON AND SEARCH -->
    <b-row
      v-if="showHeader"
      class="mb-2 d-flex flex-wrap"
      :class="[headerClasses]"
    >
      <b-col
        v-if="showButton"
        cols="12"
        md="auto"
        class="mb-1 mb-md-0 order-2 order-md-1"
      >
        <b-button variant="primary" block @click.prevent="onNewButtonClicked">
          {{ computedNewButtonText }}
        </b-button>
      </b-col>

      <b-col
        v-if="showSearch || showFilters"
        cols="12"
        md="auto"
        class="mb-1 mb-md-0 order-1 order-md-2 d-flex align-items-center"
      >
        <b-form-checkbox
          v-if="showFilters"
          v-model="showExpiredFilter"
          class="mr-2"
        >
          <span class="text-nowrap">Mostrar promocions caducades</span>
        </b-form-checkbox>
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          hover
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="filteredItems"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          :empty-filtered-text="$t('errors.emptyTable.title')"
          :fields="computedFields"
          :filter="filter"
          @filtered="onFiltered"
          @row-clicked="onRowClicked"
          @row-contextmenu="onRowRightClicked"
          @row-middle-clicked="onRowMiddleClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-img
                id="loading-gif"
                class="py-5"
                fluid-grow
                :src="
                  require('@/assets/foravila/images/gifs/house-loading-1.gif')
                "
              />
            </div>
          </template>

          <template #cell(active)="data">
            <b-badge :variant="data.value ? 'success' : 'danger'">
              {{ data.value ? "Sí" : "No" }}
            </b-badge>
          </template>

          <template #cell(expired)="data">
            <b-badge :variant="data.value ? 'danger' : 'success'">
              {{ data.value ? "Sí" : "No" }}
            </b-badge>
          </template>

          <template #cell(valid)="data">
            <b-badge :variant="data.value ? 'success' : 'danger'">
              {{ data.value ? "Sí" : "No" }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="items.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BImg,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BImg,
    BFormCheckbox,
    BBadge,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    showFilters: {
      type: Boolean,
      default: () => false,
    },
    newButtonText: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      showExpiredFilter: false,
      filter: null,
    };
  },
  computed: {
    filteredItems() {
      if (!this.items.length) return [];
      return this.items.filter((item) =>
        this.showExpiredFilter ? true : !item.expired
      );
    },
    showHeader() {
      return this.showSearch || this.showButton;
    },
    headerClasses() {
      return this.showButton
        ? "justify-content-between"
        : "justify-content-end";
    },
    computedNewButtonText() {
      return this.newButtonText || this.$t("Nou");
    },
    computedFields() {
      if (!this.fields?.length) return [];
      const fields = [...this.fields];
      return fields;
    },
  },
  watch: {
    items(items) {
      this.totalRows = items.length;
    },
  },
  created() {
    if (this.fields.length) this.sortBy = this.fields[0].key;
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onNewButtonClicked() {
      this.$emit("new-click");
    },
    onRowClicked(item) {
      this.$emit("item-click", item.uuid);
    },
    onRowRightClicked(item) {
      this.$emit("item-right-click", item.uuid);
    },
    onRowMiddleClicked(item) {
      this.$emit("item-middle-click", item.uuid);
    },
  },
};
</script>

<style lang="scss">
.static-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
  #loading-gif {
    max-width: 350px;
  }
}
</style>
